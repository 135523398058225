<template>
  <!-- modal login-->
  <b-modal
    id="modal-residencia-form"
    ref="refModalResidenciaForm"
    v-model="dialog"
    size="md"
    no-close-on-backdrop
    header-bg-variant="primary"
    :hide-footer="true"
    no-close-on-esc
    hide-header-close
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ formTitle }}
        </strong>
      </div>

    </template>
    <b-form @submit.prevent="onSubmit">

      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
        >
          <b-row no-gutters>
            <b-col
              lg="10"
              sm="10"
            >
              <label class="text-uppercase">Albergue</label>
              <vue-autosuggest
                ref="inputResidencia"
                v-model="query"
                :suggestions="suggestions"
                :render-suggestion="renderSuggestion"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{id:'autosuggest__input', placeholder:'', class: 'form-control'}"
                @selected="setResidenciaSelect"
                @input="fetchResults"
                @focus="fetchResults"
              />

            </b-col>
            <b-col
              lg="2"
              sm="2"
              class="mt-2"
            >
              <b-button
                class="mb-0"
                variant="success"
                block
                @click="crearResidencia"
              >
                <feather-icon
                  icon="SaveIcon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <b-row class="pt-1">
        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Nombre / Código"
            label-class="text-uppercase"
            label-size="sm"
          >
            <b-form-input
              v-model="residenciaData.codigo"
              placeholder="Nombre / Código"
            />
          </b-form-group>
        </b-col>

        <!-- tipo -->
        <b-col cols="6">
          <!-- Input tipo -->
          <b-form-group
            label="Tipo de habitiación"
            label-class="text-uppercase"
            label-size="sm"
          >
            <v-select
              v-model="residenciaData.tipo_id"
              class="text-uppercase"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tipos"
              label="nombre"
              :reduce="val => val.id"
              placeholder="Seleccionar"
              @input="setTipoHabitacionSelect"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Aforo"
            label-class="text-uppercase"
            label-size="sm"
          >
            <b-form-input
              v-model="residenciaData.aforo_total"
              placeholder="Total de personas"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Estados"
            label-class="text-uppercase"
            label-size="sm"
          >
            <v-select
              v-model="residenciaData.estado"
              class="text-uppercase"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="estados"
              placeholder="Seleccionar"
            />
          </b-form-group>

        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ titleButton }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="close"
          >
            Cerrar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BForm, BModal, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, toRefs, nextTick, computed,
} from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'

export default {
  components: {
    VueAutosuggest,
    vSelect,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormGroup,
    // BFormTextarea,
    BButton,
    BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
    // BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    persona: {
      type: Object,
      default() {
        return { }
      },
    },
    editedItem: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {
      tipos: [],
      estados: ['activo', 'inactivo', 'ocupado', 'disponible', 'mantenimiento', 'habitable', 'inhabitable'],
      query: '',
      selected: null,
      timeout: null,
      debounceMilliseconds: 500,
      suggestions: [],
      // form: {
      //   codigo: null,
      //   residencia_id: null,
      //   tipo_id: 2,
      //   nombre: null,
      //   estado: null,
      // },
    }
  },
  created() {
    this.$http.get('residencia/habitaciones/tipos').then(response => { this.tipos = response.data.data })
  },
  methods: {
    crearResidencia() {
      const form = { nombre: this.$refs.inputResidencia.value }
      this.$http.post('residencias', form).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Registro exitoso! ${response.data.nombre}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        if (error.response.data.code === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.error.nombre}!!`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
        if (error.response.data.code === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.error}!!`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    setResidenciaSelect(obj) {
      this.residenciaData.residencia_id = obj.item.id
    },
    setTipoHabitacionSelect(id) {
      this.residenciaData.tipo_id = id
    },
    renderSuggestion(suggestion) {
      return (
        <div class="text-uppercase font-weight-bold">{suggestion.item.nombre}</div>
      )
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.nombre.toUpperCase()
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const promise = this.$http.get(`/residencias?filter=${query}`)
        return promise.then(response => {
          this.suggestions = []
          this.selected = null

          this.suggestions = [{ data: response.data.data }]
          return this.suggestions || []
        })
      }, this.debounceMilliseconds)
    },
  },
  setup(props, { emit }) {
    const { editedItem } = toRefs(props)
    const editedIndex = ref(-1)
    const titleButton = ref('Guardar')
    const dialog = ref(false)
    const toast = useToast()
    const query = ref('')
    const form = {
      codigo: null,
      residencia_id: null,
      tipo_id: null,
      aforo_total: null,
      // nombre: null,
      estado: null,
    }

    const residenciaData = ref(JSON.parse(JSON.stringify(form)))

    const resetuserData = () => {
      residenciaData.value = JSON.parse(JSON.stringify(form))
    }

    const close = () => {
      dialog.value = false
      nextTick(() => {
        resetuserData()
        editedIndex.value = -1
      })
    }

    const formTitle = computed(() => (editedIndex.value === -1 ? 'Nueva habitación' : `Editar N° ${editedIndex.value}`))

    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    watch(editedItem, () => {
      editedIndex.value = editedItem.value.id
      // Datos de triaje
      residenciaData.value = editedItem.value
      query.value = editedItem.value.residencia.nombre.toUpperCase()
    })

    const onSubmit = () => {
      if (editedIndex.value > -1) {
      // vamos a editar

        store.dispatch('habitaciones/updatedHabitacion', residenciaData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Actualización exitosa',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            dialog.value = false
            emit('refresh-data-habitaciones-table')
          })
      } else {
        store.dispatch('habitaciones/addHabitacion', residenciaData.value)
          .then(() => {
          // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro exitoso!',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            dialog.value = false
            emit('refresh-data-habitaciones-table')
          })
      }
    }

    return {
      residenciaData,
      resetuserData,
      onSubmit,
      close,
      dialog,
      formTitle,
      titleButton,
      query,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
  /* Number Type Input Box Scss for - Remove arrow on hover */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: auto !important;
  }
</style>
