import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    fetchHabitaciones(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/habitaciones', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // show
    fetchHabitacion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/habitaciones/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // store
    addHabitacion(ctx, residenciaData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/habitaciones', residenciaData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // update
    updatedHabitacion(ctx, residenciaData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/habitaciones/${residenciaData.id}`, residenciaData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // delete
    deleteHabitacion(ctx, residenciaData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/habitaciones/${residenciaData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
